//------------------------ 03. COMPONENTS ==============================
// BUTTONS
.rv-1-def-btn {
  height: 55px;
  line-height: 53px;
  text-transform: capitalize;
  color: var(--white-f);
  font-weight: 700;
  font-size: 16px;
  display: inline-flex;
  font-family: var(--mulish);
  transition: none;

  @include xl {
    height: 50px;
    line-height: 48px;
  }

  @include md {
    height: 45px;
    line-height: 43px;
  }

  .txt {
    background-color: var(--orange);
    border-radius: 14px;
    padding: 0 35px;
    transition: 0.4s ease;
    border: 1px solid var(--orange);

    @include xl {
      padding: 0 30px;
    }

    @include md {
      padding: 0 20px;
    }
  }

  .icon {
    border: 1px solid var(--orange);
    transition: 0.4s ease;
    background-color: var(--orange);
    border-radius: 50%;
    aspect-ratio: 1;
    width: 55px;
    text-align: center;
    line-height: 50px;

    @include xl {
      line-height: 45px;
      width: 50px;
    }

    @include md {
      line-height: 40px;
      width: 45px;
    }
  }

  &:hover {
    .txt {
      background-color: var(--white-f);
    }

    .icon {
      border-radius: 14px;
    }
  }

  &-2 {
    color: var(--black-0);

    &:hover {
      .txt {
        color: var(--white-f);
        background-color: var(--orange);
        border-color: var(--orange);
      }
    }

    .txt,
    .icon {
      background-color: var(--white-f);
      border-color: var(--white-f);
    }
  }
}

.rv-section-spacing {
  padding: 120px 0;

  @include xl {
    padding: 80px 0;
  }

  @include md {
    padding: 60px 0;
  }
}

// INDEX-1 SECTION HEADING, TITLE
.rv-1-section {
  &__sub-title {
    font-family: var(--mulish);
    font-weight: 600;
    font-size: 15px;
    color: var(--black);
    text-transform: uppercase;

    @include xxs {
      font-size: 14px;
    }
  }

  &__title {
    font-family: var(--inter);
    color: var(--black);
    font-weight: 700;
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 28px;

    @include xl {
      font-size: 45px;
      margin-bottom: 23px;
    }

    @include lg {
      font-size: 40px;
      margin-bottom: 18px;
    }

    @include md {
      font-size: 35px;
    }

    @include sm {
      font-size: 32px;
    }

    @include xs {
      font-size: 30px;
    }

    @include xxs {
      font-size: 25px;
    }
  }
}

.rv-1-section-heading-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  @include xl {
    margin-bottom: 38px;
  }

  @include md {
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
  }

  @include sm {
    margin-bottom: 33px;
  }

  > * {
    width: 50%;

    @include md {
      width: 100%;
    }
  }

  .rv-1-section {
    &-heading__right {
      display: flex;
      justify-content: flex-end;
      gap: 24px;

      @include md {
        justify-content: center;
      }

      @include xxs {
        gap: 14px;
      }

      .rv-1-def-btn {
        border-radius: 14px;
        line-height: 53px;
        transition: 0.4s ease;

        @include lg {
          line-height: 48px;
        }

        @include md {
          line-height: 43px;
        }

        &:hover {
          background-color: var(--orange);
          border-color: var(--orange);

          .txt {
            color: var(--white-f);
          }
        }

        .txt {
          border: 1.5px solid rgb(5 5 5 / 14%);
          background-color: transparent;
          color: var(--black);

          @include xxs {
            padding: 0 20px;
            font-size: 15px;
          }
        }
      }
    }

    &__title {
      margin-bottom: 0;
    }
  }
}

// INDEX-1 SLIDER NAV
.rv-1-slider-nav {
  display: flex;
  gap: 6px;

  button {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1.5px solid rgb(5 5 5 / 14%);

    @include xl {
      width: 50px;
      height: 50px;
      line-height: 48px;
    }

    @include md {
      width: 45px;
      height: 45px;
      line-height: 43px;
    }

    &:hover {
      color: var(--white-f);
      background-color: var(--orange);
      border-color: var(--orange);
    }
  }
}

// moaal
.rv-search-modal {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: rgba(253, 253, 253, 0.44);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s cubic-bezier(0.16, 0.71, 0.17, 1);
  clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0% 50%);

  form {
    display: flex;
    background-color: var(--white-f);
    width: 70%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgb(5 5 5 / 10%);
    overflow: hidden;

    @include xxs {
      width: 95%;
      height: 50px;
    }

    input {
      width: 100%;
      padding: 0 20px;
    }

    button {
      width: 60px;
      height: 60px;
      background-color: rgb(5 5 5 / 10%);
      font-size: 20px;

      @include xxs {
        height: 50px;
        width: 50px;
        font-size: 18px;
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &-close-btn {
    border: 1px solid rgb(5 5 5 / 24%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: var(--black);
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 18px;

    &:hover {
      color: var(--white-f);
      background-color: var(--black);
      border-color: var(--black);
    }
  }
}

// INDEX-2
.rv-2-slider-nav button {
  border-color: rgb(255 255 255 / 14%);
  color: var(--white-f);

  @include xl {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }

  &:hover {
    background-color: #daed1a;
    border-color: #daed1a;
    color: var(--black);
  }
}

.rv-2-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 58px;

  @include xxl {
    margin-bottom: 38px;
  }

  @include sm {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    text-align: center;
    margin-bottom: 28px;
  }

  .rv-2-section-title {
    margin: 0;
  }
}

.rv-2-section-title {
  font-weight: 700;
  font-size: 50px;
  color: var(--white-f);
  margin-bottom: 60px;

  @include xxl {
    margin-bottom: 40px;
  }

  @include xl {
    font-size: 40px;
  }

  @include lg {
    font-size: 40px;
  }

  @include md {
    margin-bottom: 30px;
  }

  @include sm {
    font-size: 35px;
  }

  @include xs {
    font-size: 30px;
    margin-bottom: 25px;
  }
}

.rv-1-socials.rv-2-socials a {
  background-color: var(--white-f);
  color: var(--black);

  &:hover {
    background-color: #daed1a;
    border-color: #daed1a;
    color: var(--black);
  }
}

// INDEX-3
.rv-3-sub-title {
  font-family: var(--mulish);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  width: max-content;
  max-width: 100%;
  margin-left: 20px;
  margin-bottom: 35px;

  @include xl {
    margin-bottom: 25px;
  }

  @include lg {
    margin-bottom: 15px;
  }

  @include sm {
    margin: auto;
    margin-bottom: 15px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: -10px;
    top: -5px;
    height: calc(100% + 20px);
    width: 1px;
    background-color: rgb(5 5 5 / 30%);
    transition: 1.4s ease;
    animation: subTitleHeight 1.9s linear infinite alternate;
  }

  &::after {
    top: auto;
    width: calc(100% + 30px);
    height: 1px;
    bottom: -6px;
    left: -20px;
    animation-name: subTitleWidth;
  }
}

@keyframes subTitleHeight {
  0% {
    height: 0;
  }

  100% {
    height: calc(100% + 20px);
  }
}

@keyframes subTitleWidth {
  0% {
    width: 0;
  }

  100% {
    width: calc(100% + 30px);
  }
}

.rv-3-def-btn {
  font-family: var(--mulish);
  height: 50px;
  line-height: 47px;
  padding: 0 35px;
  border: 1px solid rgba(169, 114, 64, 1);
  background-color: rgba(169, 114, 64, 1);
  color: var(--white-f);

  @include lg {
    height: 45px;
    line-height: 42px;
    padding: 0 30px;
  }

  @include sm {
    height: 40px;
    line-height: 37px;
    padding: 0 25px;
  }

  @include xxs {
    font-size: 14px;
    height: 35px;
    line-height: 33px;
    padding: 0 20px;
  }

  i {
    padding-left: 4px;
  }

  &:hover {
    background-color: var(--white-f);
  }
}

// section heading
.rv-3-section {
  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 58px;
    gap: 30px;

    @include xl {
      margin-bottom: 43px;
    }

    @include lg {
      margin-bottom: 33px;
    }

    @include sm {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 20px;
      margin-bottom: 28px;
    }

    @include xxs {
      margin-bottom: 23px;
    }

    .rv-3-section-title {
      margin-bottom: 0;
    }

    &__left {
      width: 50%;

      @include lg {
        width: 60%;
      }

      @include md {
        width: auto;
      }
    }

    &__right {
      margin-bottom: 12px;
      flex-shrink: 0;

      @include md {
        margin-bottom: 7px;
      }

      @include sm {
        margin-bottom: 0;
      }
    }
  }

  &-title {
    color: #050505;
    font-size: 50px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 21px;

    @include xl {
      font-size: 40px;
    }

    @include lg {
      font-size: 37px;
    }

    @include md {
      font-size: 32px;
    }

    @include sm {
      font-size: 28px;
    }

    @include xxs {
      font-size: 25px;
    }
  }
}

// INDEX-3
.rv-3-slider-pagination {
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    border-radius: 0;
    opacity: 1;
    background-color: rgb(5 5 5 / 10%);
    transition: 0.4s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #a97240;
  }
}

// INDEX-4
// pagination
.rv-4-slider-pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;

  @include xl {
    margin-top: 30px;
  }

  @include lg {
    margin-top: 25px;
  }

  .swiper-pagination-bullet {
    margin: 0;
    background-color: rgba(5, 5, 5, 0.1);
    opacity: 1;
    border-radius: 30px;
    width: 10px;
    height: 10px;
    transition: 0.4s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #a90e20;
    width: 40px;
  }
}

// INDEX-5
// section heading, title, sub-title
.rv-5-section {
  &__heading {
    text-align: center;
    width: 50%;
    margin: auto;
    position: relative;

    @include sm {
      width: 90%;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: url(../../img/rv-5-section-heading-bg.png) no-repeat center
        center;
      background-size: 100% 100%;
      width: 437px;
      height: 198px;
      top: -30px;
      left: 50%;
      translate: -50% 0;
      z-index: -1;
    }
  }

  &__sub-title {
    color: #8e5b63;
    font-family: var(--philosopher);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &__title {
    color: #050505;
    font-family: var(--philosopher);
    font-size: 60px;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 50px;

    @include xxl {
      font-size: 50px;
    }

    @include xl {
      font-size: 45px;
    }

    @include lg {
      margin-bottom: 35px;
      font-size: 40px;
    }

    @include sm {
      font-size: 35px;
      margin-bottom: 25px;
    }

    @include xs {
      font-size: 32px;
      margin-bottom: 20px;
    }

    @include xxs {
      font-size: 30px;
    }
  }
}

// INDEX-6
.rv-6-def-btn {
  height: 52px;
  line-height: 52px;
  border-radius: 6px;
  background-color: rgba(5, 5, 5, 0.06);
  padding: 0 42px;
  color: #050505;
  font-weight: 600;

  &:hover {
    background-color: #f2584c;
    color: var(--white-f);

    i {
      color: var(--white-f);
    }
  }

  @include lg {
    padding: 0 32px;
  }

  @include md {
    padding: 0 26px;
    height: 46px;
    line-height: 46px;
  }

  i {
    padding-left: 5px;
    color: #f2584c;
  }
}

.rv-6-section {
  &__heading {
    color: #050505;
    margin-bottom: 46px;

    @include lg {
      margin-bottom: 36px;
    }

    @include md {
      margin-bottom: 28px;
    }

    @include sm {
      margin-bottom: 22px;
    }
  }

  &__sub-title {
    font-size: 15px;
    font-weight: 500;
    padding-left: 16px;
    position: relative;
    margin-bottom: 12px;
    display: inline-block;

    @include xs {
      margin-bottom: 6px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #050505;
      left: 0;
      top: 50%;
      translate: 0 -50%;
    }
  }

  &__title {
    font-size: 46px;
    font-weight: 700;
    line-height: 130.435%;

    @include xl {
      font-size: 40px;
    }

    @include lg {
      font-size: 35px;
    }

    @include sm {
      font-size: 32px;
    }

    @include xs {
      font-size: 26px;
    }

    @include xxs {
      font-size: 24px;
    }
  }
}

// INDEX-7 COMPONENTS
.rv-7-section__heading {
  text-align: center;
  margin-bottom: 47px;

  @include xl {
    margin-bottom: 27px;
  }

  @include lg {
    margin-bottom: 22px;
  }

  @include md {
    margin-bottom: 17px;
  }
}

.rv-7-section__sub-title {
  color: #050505;
  font-family: var(--oldenburg);
  font-size: 16px;
  font-weight: 400;
}

.rv-7-section__title {
  color: #050505;
  font-family: var(--mulish);
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0;

  @include xl {
    font-size: 40px;
  }

  @include lg {
    font-size: 35px;
  }

  @include md {
    font-size: 30px;
  }

  @include sm {
    font-size: 27px;
  }

  @include xxs {
    font-size: 25px;
  }
}

.rv-7-slider-scrollbar {
  height: 2px;
  background: rgb(5 5 5 / 8%);
  width: 77%;
  margin: auto;
  margin-top: 50px;

  @include lg {
    margin-top: 35px;
  }

  .swiper-scrollbar-drag {
    background-color: #4962fb;
  }
}

// INDEX-8 COMPONENTS
.rv-8-section__heading {
  text-align: center;
  margin-bottom: 60px;

  @include xl {
    margin-bottom: 45px;
  }

  @include lg {
    margin-bottom: 35px;
  }

  @include md {
    margin-bottom: 30px;
  }

  @include sm {
    margin-bottom: 25px;
  }

  @include xxs {
    margin-bottom: 20px;
  }
}

.rv-8-section__sub-title {
  font-family: var(--mulish);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 18px;
  display: inline-block;
  position: relative;
  color: rgba(0, 0, 0, 0.7);

  @include xxs {
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    translate: 0 -50%;
    width: 8px;
    height: 8px;
    background-color: var(--black);
  }
}

.rv-8-section__title {
  color: #050505;
  font-family: var(--mulish);
  font-size: 46px;
  font-weight: 800;
  line-height: 117.391%;

  @include xl {
    font-size: 42px;
  }

  @include lg {
    font-size: 36px;
  }

  @include sm {
    font-size: 32px;
  }

  @include xxs {
    font-size: 26px;
  }

  .styled {
    position: relative;

    svg {
      position: absolute !important;
      left: 50%;
      translate: -50% 0;
      bottom: -6px;
      z-index: -1;

      @include xxs {
        width: 130px;
      }
    }
  }
}

.rv-8-slider-nav {
  button {
    border-radius: 0;

    &:hover {
      background-color: #1c74e9;
      border-color: #1c74e9;
    }
  }
}

// INDEX-9 COMPONENTS
.rv-9-section {
  &__heading {
    font-family: var(--mulish);
    margin: auto;
    width: 55%;
    text-align: center;
    margin-bottom: 48px;

    @include xl {
      margin-bottom: 38px;
    }

    @include lg {
      margin-bottom: 28px;
    }

    @include md {
      width: 65%;
    }

    @include sm {
      width: 80%;
      margin-bottom: 23px;
    }

    @include xxs {
      width: 100%;
    }
  }

  &-heading__right {
    margin-bottom: 20px;
  }

  &__sub-title {
    color: #050505;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid rgba(5, 5, 5, 0.14);
    display: inline-block;
    padding: 8px 20px 8px 30px;
    position: relative;
    overflow: hidden;

    @include xxs {
      padding: 5px 15px 5px 25px;
      font-size: 15px;
    }

    &::before {
      position: absolute;
      content: "";
      left: 20px;
      top: 50%;
      translate: 0 -50%;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #050505;

      @include xxs {
        left: 15px;
      }
    }
  }

  &__title {
    color: #050505;
    font-size: 54px;
    font-weight: 800;
    line-height: 118.519%;

    @include xl {
      font-size: 48px;
    }

    @include lg {
      font-size: 42px;
    }

    @include md {
      font-size: 38px;
    }

    @include sm {
      font-size: 32px;
    }

    @include xs {
      font-size: 28px;
    }

    @include xxs {
      font-size: 26px;
    }
  }
}

.rv-9-slider-nav button:hover {
  background-color: #65ab03;
  border-color: #65ab03;
}

.rv-2-swiper-dots.rv-9-slider-dots {
  display: flex;
  margin: 0;
  height: max-content;
  gap: 10px;

  .swiper-pagination-bullet::before {
    background-color: rgb(255 255 255 / 24%);
  }

  .swiper-pagination-bullet-active {
    border-color: var(--white-f);

    &::before {
      background-color: var(--white-f);
    }
  }
}

// INDEX-10 COMPONENTS
.rv-10-def-btn {
  background-color: #efc329;
  padding: 0 44px;
  border-color: #efc329;
  border-radius: 6px;
  color: var(--black);
  font-weight: 700;
  height: 55px;
  line-height: 52px;

  @include lg {
    height: 50px;
    line-height: 47px;
    padding: 0 34px;
  }

  @include md {
    height: 45px;
    line-height: 42px;
    padding: 0 30px;
  }

  @include sm {
    height: 42px;
    line-height: 39px;
    padding: 0 24px;
  }

  &:hover {
    background-color: transparent;
    color: var(--black);
  }
}

.rv-10-section {
  color: var(--black);

  &__heading {
    width: 60%;
    margin: auto;
    text-align: center;
    margin-bottom: 48px;

    @include xl {
      margin-bottom: 33px;
    }

    @include lg {
      margin-bottom: 28px;
    }

    @include md {
      width: 70%;
      margin-bottom: 23px;
    }

    @include sm {
      width: 90%;
    }

    @include xs {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  &__sub-title {
    font-family: var(--oldenburg);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;

    @include xl {
      margin-bottom: 16px;
    }

    @include lg {
      margin-bottom: 14px;
    }

    @include xs {
      font-size: 16px;
    }

    @include xs {
      margin-bottom: 8px;
    }

    .styled {
      background-color: #efc329;
      padding: 0 3px;
    }
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    line-height: 120%;

    @include xl {
      font-size: 42px;
    }

    @include lg {
      font-size: 36px;
    }

    @include sm {
      font-size: 32px;
    }

    @include xs {
      font-size: 28px;
    }

    @include xxs {
      font-size: 26px;
    }
  }
}

// INDEX-11 COMPONENTS
.rv-11-def-btn {
  border-radius: var(--rv-11-radius);
  background-color: #a98153;
  border-color: #a98153;

  &:hover {
    border-radius: 0 20px;
  }
}

.rv-11-section {
  &__heading {
    margin-bottom: 50px;

    @include xl {
      margin-bottom: 35px;
    }

    @include md {
      margin-bottom: 25px;
    }

    @include xxs {
      margin-bottom: 18px;
    }
  }

  &__sub-title {
    color: #a98153;
    font-family: var(--mulish);
    font-weight: 600;
    line-height: 162.5%;
    position: relative;
    display: inline-block;
    margin: 0 88px;
    margin-bottom: 15px;

    @include lg {
      margin-bottom: 5px;
    }

    @include xxs {
      margin: 0 48px;
    }

    span {
      position: absolute;
      width: 50px;
      height: 1px;
      background-color: rgb(168 129 84 / 40%);
      top: 50%;
      translate: 0 -50%;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        rotate: 45deg;
        background-color: rgb(168 129 84 / 40%);
        top: 50%;
        translate: 0 -50%;
      }

      &::before {
        right: 100%;
        animation: rv-11-slide-right 3s linear infinite alternate both;
      }

      &::after {
        left: auto;
        animation: rv-11-slide-left 3s linear infinite alternate both;
      }

      &.stroke-1 {
        right: calc(100% + 25px);
      }

      &.stroke-2 {
        left: calc(100% + 25px);
      }
    }
  }

  &__title {
    color: var(--black);
    font-family: var(--mulish);
    font-size: 40px;
    font-weight: 700;
    line-height: 125%;

    @include xl {
      font-size: 35px;
    }

    @include lg {
      font-size: 32px;
    }

    @include xs {
      font-size: 30px;
    }

    @include xxs {
      font-size: 26px;
    }
  }
}

// INDEX-12 COMPONENTS
.rv-12-body {
  .rv-7-section__sub-title {
    font-size: 15px;
    color: #509e0f;
  }
}

.rv-12-def-btn {
  background-color: #509e0f;
  border-color: #509e0f;
}

.rv-12-slider-nav {
  justify-content: center;
  position: relative;
  width: max-content;
  margin: auto;
  max-width: 100%;
  margin-top: 64px;

  @include xl {
    margin-top: 44px;
  }

  button {
    height: 40px;
    width: 40px;
    line-height: normal;

    &:hover {
      background-color: #509e0f;
      border-color: #509e0f;
    }
  }
}

// INDEX-13 COMPONENTS
.rv-13-section-heading {
  @include md {
    flex-direction: column;
    align-items: center;
  }
}

.rv-13-section__sub-title {
  font-size: 18px;
  font-family: var(--mulish);
  font-weight: 600;
  color: #ab8e66;
}

.rv-13-def-btn {
  background-color: #ab8e66;
  border-color: #ab8e66;
}

.rv-13-slider-nav-2 {
  line-height: 1;
  display: flex;

  button {
    font-size: 25px;

    &:first-child {
      margin-right: 15px;
      padding-right: 15px;
      border-right: 1px solid rgb(5 5 5 / 10%);
    }
  }
}

.rv-13-product-quantity-tooltip {
  position: absolute;
  background-color: #ab8e66;
  left: 50%;
  translate: -50% 0;
  bottom: calc(100% + 5px);
  line-height: 1.5;
  padding: 0 8px;
  color: var(--white-f);
  font-weight: 400;
  font-family: var(--mulish);
  font-size: 12px;

  &::before {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    clip-path: polygon(0 0, 100% 0, 50% 100%, 50% 100%);
    background-color: #ab8e66;
    height: 5px;
    width: 12px;
    left: 50%;
    translate: -50% 0;
  }
}

// INDEX-14 COMPONENTS
.rv-14-section {
  &__heading {
    margin-bottom: 73px;

    @include xxl {
      margin-bottom: 43px;
    }

    @include md {
      margin-bottom: 33px;
    }

    .rv-3-section-heading__left {
      // width: 50%;
    }

    .rv-3-section-heading__right {
      margin-bottom: 7px;
    }
  }

  &__sub-title {
    color: var(--black);
    font-family: var(--mulish);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.96px;
    margin-bottom: 10px;
  }

  &__descr {
    margin-bottom: 0;
    margin-top: 17px;
  }
}

.rv-14-body {
  .rv-7-section__title {
    font-weight: 800;
    line-height: 122.727%;
  }
}

.rv-14-def-btn {
  background-color: #e64209;
  border: 1px solid #e64209;
  border-radius: 40px;
  color: var(--white-f);
  font-family: var(--mulish);
  line-height: 53px;

  @include lg {
    line-height: 48px;
  }

  @include md {
    line-height: 43px;
  }

  @include sm {
    line-height: 40px;
  }

  &:hover {
    color: var(--black);
    border-color: var(--black);
  }

  i {
    padding-left: 5px;
  }
}

// INNER PAGE
// breadcrumb
.rv-breadcrumb {
  background-color: rgba(245, 246, 250, 1);
  background-color: #f1ffed;
  text-align: center;
  position: relative;
  z-index: 1;

  @include xl {
    padding: 80px 0;
  }

  @include md {
    padding: 60px 0;
  }

  &__title {
    font-size: 54px;
    font-weight: 700;
    border-bottom: 1px solid rgba(5, 5, 5, 0.1);
    display: inline-block;
    padding: 0 70px;
    padding-bottom: 10px;
    margin-bottom: 16px;

    @include xl {
      font-size: 50px;
    }

    @include lg {
      font-size: 45px;
    }

    @include md {
      font-size: 40px;
    }

    @include sm {
      font-size: 38px;
    }

    @include xs {
      font-size: 35px;
      padding: 0;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    @include xxs {
      font-size: 32px;
    }
  }

  &__nav {
    font-weight: 500;

    a {
      color: rgba(93, 98, 108, 1);

      &:hover {
        color: #509e0f;
      }

      i {
        vertical-align: text-top;
        padding-left: 5px;
      }
    }

    .current-page {
      color: #509e0f;

      .dvdr {
        padding: 0 10px;
        color: rgba(93, 98, 108, 1);
      }
    }
  }
}

// button
.rv-inner-btn {
  background-color: #509e0f;
  border-color: #509e0f;

  &:hover {
    color: #509e0f;
  }
}

// footer
.rv-inner-footer {
  background-color: #012307;

  &-nwsltr__form {
    .nwsltr-top {
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.04);

      input {
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 6px 0 0 6px;
      }

      button {
        border-radius: 0 6px 6px 0;
        background-color: #509e0f;
      }
    }
  }

  &-widget {
    .rv-1-footer-widget__title {
      @include lg {
        margin-bottom: 15px;
      }
    }
  }
}

// inner socials
.rv-1-socials.rv-inner-socials {
  a {
    border-radius: 6px;

    &:hover {
      background-color: #509e0f;
      border-color: #509e0f;
    }
  }
}

.rv-1-footer-widget.rv-inner-footer-widget {
  li,
  li a {
    &:hover {
      color: #509e0f;
    }
  }
}

// index-15 socials
.rv-1-socials.rv-15-socials {
  a {
    border-radius: 0;

    &:hover {
      background-color: #f35b04;
    }
  }
}

// INDEX-17 SOCIALS
.rv-1-socials.rv-17-socials {
  a:hover {
    background-color: #daed1a;
    border-color: #daed1a;
    color: var(--black);
  }
}

.rv-1-socials.rv-18-socials a:hover {
  background-color: #1c74e9;
  border-color: #1c74e9;
}

.rv-1-socials.rv-19-socials a:hover {
  background-color: #dfe11b;
  border-color: #dfe11b;
  color: #050505;
}

// INDEX-20 SOCIALS
.rv-1-socials.rv-20-socials {
  a:hover {
    background-color: #447183;
    border-color: #447183;
  }
}

.rv-21-footer .rv-1-socials.rv-21-socials a {
  color: rgb(14, 20, 40, 0.5);
  border-color: rgb(14, 20, 40, 0.1);

  &:hover {
    background-color: #ce512a;
    border-color: #ce512a;
    color: var(--white-f);
  }
}

// INDEX-22 COMP
.rv-3-def-btn.rv-22-def-btn {
  background-color: #b38d3f;
  border-color: #b38d3f;
  height: 50px;
  line-height: 47px;

  @include md {
    height: 45px;
    line-height: 43px;
  }

  &:hover {
    background-color: transparent;
  }
}

// lightbox
.lightbox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: url(../../img/cursor.png), zoom-out;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 1000;
  &.active {
    opacity: 1;
    visibility: visible;
    .lightbox-img {
      transform: scale(1);
      transition: 0.3s;
    }
  }
  .lightbox-img {
    transform: scale(0);
    cursor: context-menu;
    background: #eef6e7;
    padding: 25px 40px;
    border-radius: 10px;
    img {
      max-width: 100%;
    }
  }
}
